:root {
  --toastify-toast-width: 400px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'DMSans';
    src:
      url('../public/fonts/DMSans-Regular.woff2') format('woff2'),
      url('../public/fonts/DMSans-Regular.ttf') format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'DMSansBold';
    src:
      url('../public/fonts/DMSans-Bold.woff2') format('woff2'),
      url('../public/fonts/DMSans-Bold.ttf') format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'DMSansMedium';
    src:
      url('../public/fonts/DMSans-Medium.woff2') format('woff2'),
      url('../public/fonts/DMSans-Medium.ttf') format('truetype');
    font-display: swap;
  }
}

html,
body,
#root,
#root > main {
  height: 100%;
  background: #ffffff;
}

.Toastify__toast {
  padding: 0px;
}

.Toastify__toast-container {
  background: white;
  padding: 0px;
}

body {
  margin: 0;
  font-family: 'DMSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.learner #path0 {
  stroke-width: 3;
}
.learner #path1 {
  stroke-width: 3;
}
